<template >
  <div>
    <v-container >
      <v-card class="mx-auto pa-5 mt-5" max-width="1264" elevation="4">
        <v-row class="align-center" >
          <v-col cols="12" md="4" >
            <v-img height="160" contain class="pa-6" :src="shopInfo.picture"></v-img>
          </v-col>
          <v-col cols="12" md="8">
            <v-list-item three-line class="text-center" >
              <v-list-item-content>
                <v-list-item-title style="height:auto" class="headline text-ellipsis font-weight-bold mb-5">{{shopInfo.name}}</v-list-item-title>
                <v-list-item-subtitle style="height:auto" class="title text-ellipsis mb-2" >{{$t('cart.order_min_price_title')}}: <b>{{shopInfo.min_price | formatCurrency}}</b></v-list-item-subtitle>
                <v-list-item-subtitle style="height:auto" class="title text-ellipsis mb-5" >{{$t('cart.delivery_cost')}}: <b>{{shopInfo.min_shipping_price | formatCurrency}}</b></v-list-item-subtitle>
                <v-btn rounded block dark large color="pediidos" v-if="!isCustomer" @click="applyBeClient()" >{{$t('home.be_client')}}</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mx-auto pa-5 mt-5" max-width="1264" min-height="60vh" elevation="4" id="mytop" >
        <v-row >
          <v-col cols="12" md="6">
            <v-expansion-panels>
              <v-expansion-panel >
                <v-expansion-panel-header class="px-3 subtitle-1 grey--text text--darken-2" >
                  <v-icon style="flex:none;" left >mdi-shape-plus</v-icon>{{$t('categories')}}</v-expansion-panel-header>
                <v-expansion-panel-content class="mx-n6 my-n4">
                  <template >
                    <v-card class="overflow-y-auto c-scroll" max-height="300" >
                      <v-sheet rounded class="pa-2 ma-1 pediidos lighten-2">
                        <v-text-field
                          color="pediidos"
                          v-model="CatSearch"
                          :label="this.$t('search_category')"
                          dark
                          flat
                          solo-inverted
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                      </v-sheet>
                      <v-container>
                        <v-treeview style="background-color: white;"
                          :items="categories"
                          :search="CatSearch"
                          :filter="CatFilter"
                          :open.sync="catOpen"
                          :active.sync="catActive"
                          @update:active="updateActive"
                          @update:open="updateOpen"
                          hoverable
                          color="pediidos"
                          activatable
                          open-on-click>
                          <template v-slot:prepend="{ item }">
                            <v-avatar size="32" class="mt-0 mb-0" v-if="item.picture" >
                              <v-img :src="item.picture"></v-img>
                            </v-avatar>
                          </template>
                        </v-treeview>
                      </v-container>
                    </v-card>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" md="6" >
            <v-text-field
              solo
              height="60"
              v-model.trim="inputSearch"
              :label="this.$t('search_product')"
              color="pediidos"
              :error-messages="errorSearch"
              @change="searchEmpty()"
              autocomplete="off"
              append-icon="mdi-close"
              prepend-inner-icon="mdi-magnify"
              @click:append="clearSearch()"
              @click:prepend-inner="searchProduct(inputSearch)"
              @keyup.enter="searchProduct(inputSearch)"
              ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="2" v-for="item in products" :key="item.product.id" >
            <v-card class="text-center align-center justify-center pa-1" >
              <v-img height="100" contain class="pa-6 mb-1"
              :src="item.product.product.picture"></v-img>
              <v-card-text v-if="enablePrice || isCustomer" class="subtitle-1 font-weight-black pb-0">{{item.price | formatCurrency}}</v-card-text>
              <v-card-text class="caption text-uppercase text-ellipsis pa-0 mb-2">{{item.product.product.name}}</v-card-text>
              <div class="mb-2" v-if="isCustomer">
                <v-card-actions class="pa-0 ma-0 justify-center"  v-if="item.quanty < 1" >
                  <v-btn outlined rounded color="pediidos" @click="changeProductCart('add',item)">
                    {{$t('_add')}}
                    <v-icon right>mdi-cart-arrow-down</v-icon>
                  </v-btn>
                </v-card-actions >
                <v-card-actions class="pa-0 ma-0 "  v-else>
                  <v-btn class="mx-2" fab x-small outlined dark color="error" @click="changeProductCart('rem',item)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-card-text class="pa-1" >{{item.quanty}}</v-card-text>
                  <v-btn class="mx-2" fab x-small outlined dark color="success" @click="changeProductCart('add',item)" >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <div class="text-center mt-10">
          <v-pagination v-model="currentPage" :length="totalPages" :disabled="pageDisabled" total-visible="7" circle color="pediidos" ></v-pagination>
        </div>

      </v-card>
    </v-container>
  </div>
</template>

<script type="text/javascript">
  import Api from '@/helpers/api.js';
  import Utils from '@/helpers/Utils.js';
  import { mapMutations, mapState } from 'vuex';
  export default {
    name: 'Price-list',
    data() {
      return {
        shopInfo:{
          id: 0,
          name: "",
          picture: "",
          min_price: 0,
          min_shipping_price: 0,
        },
        categories:[],
        totalPages: 0,
        pageDisabled: false,
        currentPage: 1,
        isSearch: false,
        inputSearch: '',
        errorSearch:'',
        catalog: 0,
        catActive: ['cat-all'],
        catOpen: [],
        catSelected: 'cat-all',
        CatSearch: null,
        isCustomer: true,
        enablePrice: true,
      }
    },
    filters:{
      formatCurrency(currency) {
        return Utils.formatCurrency(currency);
      },
    },
    watch: {
      currentPage(value) {
        if(this.isSearch){
          this.searchProduct(this.inputSearch,value);
        }else if(this.catActive[0] == 'cat-all' && this.isSearch == false){
          this.getAllProducts(value);
        }else if (this.catActive[0] != 'cat-all' && this.isSearch == false){
          let subcategory_id = this.catActive[0];
          this.getProductBySubcategory(subcategory_id,value);
        }
      },
      products(){
        this.UPDATE_QUANTY_PRODUCTS({'shop':this.shopInfo.id});
      },
    },
    async mounted(){
      this.SET_PRODUCTS([]);
      await this.getAllProducts(this.currentPage);
      await this.getShopInfo();
    },
    methods: {
      ...mapMutations(['APP_LOADER','SET_PRODUCTS','ROVOVE_CART_ZEROS','UPDATE_QUANTY_PRODUCTS','SET_CART']),
      async getShopInfo() {
        let data = {shop_id: this.$route.params.id}
        Api.post('shopkeepers/info/',data).then((result)=> {
          if(result.status < 300) {
            this.shopInfo.id = result.data.id;
            this.shopInfo.name = result.data.name;
            this.shopInfo.picture = result.data.picture;
            this.shopInfo.min_price = result.data.min_price;
            this.shopInfo.min_shipping_price = result.data.min_shipping_price;
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
      },
      async getCategories() {
        let data = {
          shop_id: this.$route.params.id,
          list_id: this.catalog
          }
        Api.post('shopkeepers/catalog/categories/',data).then((result)=> {
          if(result.status < 300) {
            result.data.forEach(function(e) {
              e.id = 'cat-'+e.categoryid;
              e.children = e.subcategories;
              delete e.subcategories;
            });
            result.data.unshift({
              'id':'cat-all',
              'picture': 'https://firebasestorage.googleapis.com/v0/b/pediidos-f9d72.appspot.com/o/misitio%2Fpictures_categories%2Fdefault-category.png?alt=media',
              'name': this.$t('all'),
              });
            this.categories = result.data;
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
      },
      updateActive(item) {
        if(item.length == 0){
          this.catActive = [this.catSelected];
        }else{
          this.catSelected = item[0];
          this.getProductBySubcategory(item[0],1);
        }
      },
      updateOpen(item) {
        if(item.length > 0){
          this.catOpen = item.slice(-1);
        }
      },
      async getAllProducts(page) {
        this.APP_LOADER(true);
        this.isSearch = false;
        this.inputSearch = '';
        this.catActive = ['cat-all'];
        this.catOpen = [],
        this.pageDisabled = true;
        let data = {
          shop_id: this.$route.params.id,
          offset: '20',
          page: page,
        }
        Api.post('shopkeepers/catalog/products/',data).then((result)=> {
          if(result.status < 300) {
            this.isCustomer = result.data.customer;
            if('enable_price' in result.data){
              this.enablePrice = result.data.enable_price; ///validar esto
            }
            result.data.data.forEach(e => e.quanty = 0);
            let myProducts = result.data.data.sort((a, b) => {
              if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0
            });
            this.SET_PRODUCTS(myProducts);
            this.currentPage = parseInt( result.data.pagination.actual_page );
            this.totalPages = parseInt( result.data.pagination.num_pages );
            this.catalog = result.data.data[0].catalog;
            if(this.categories.length < 1){
              this.getCategories();
            }
          }

        }).catch((error)=> {
          console.log('This is error ',error);
        }).finally(()=>{
          this.$vuetify.goTo('#mytop');
          this.APP_LOADER(false);
          setTimeout(()=>{this.pageDisabled = false;}, 2000);
        })
      },
      async getProductBySubcategory(subcategory_id, page) {
        this.APP_LOADER(true);
        this.isSearch = false;
        this.inputSearch = '';
        if(subcategory_id == "cat-all"){
          return this.getAllProducts(1);
        }
        this.pageDisabled = true;
        let data = {
          shop_id: this.$route.params.id,
          list_id: this.catalog,
          subcategory_id: subcategory_id,
          offset: '20',
          page: page,
        }
        Api.post('shopkeepers/catalog/subcategory/products/',data).then((result)=> {
          if(result.status < 300) {
            result.data.data.forEach(e => e.quanty = 0);
            let myProducts = result.data.data.sort((a, b) => {
              if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0
            });
            this.SET_PRODUCTS(myProducts);
            this.totalPages = parseInt( result.data.pagination.num_pages );
            this.currentPage = parseInt( result.data.pagination.actual_page );
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        }).finally(()=>{
          this.$vuetify.goTo('#mytop');
          this.APP_LOADER(false);
          setTimeout(()=>{this.pageDisabled = false;}, 2000);
        })
      },
      async searchProduct(inputSearch,page){
        this.APP_LOADER(true);
        this.isSearch = true;
        this.catOpen = [];
        this.errorSearch = '';
        if( inputSearch.length > 2){
          let data = {
            shop_id: String(this.$route.params.id),
            list_id: String(this.catalog),
            search:  inputSearch,
            offset: '20',
            page: page
          }
          Api.post('shopkeepers/catalog/search/products/',data).then((result)=> {
            if(result.status < 300) {
              result.data.data.forEach(e => e.quanty = 0);
              let myProducts = result.data.data.sort((a, b) => {
                if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0
              });
              this.SET_PRODUCTS(myProducts);
              this.totalPages = result.data.pagination.num_pages ;
              this.currentPage = result.data.pagination.actual_page ;
              this.isSearch = true;
            }
          }).catch((error)=> {
            console.log('This is error ',error);
          }).finally(()=>{
          this.$vuetify.goTo('#mytop');
          this.APP_LOADER(false);
          setTimeout(()=>{this.pageDisabled = false;}, 2000);
        })
        }else{
          this.errorSearch = this.$t('error.search_length_3');
        }

      },
      clearSearch(){
        this.inputSearch = '';
        this.errorSearch = '';
        this.catActive = ['cat-all'];
        this.isSearch = false;
        document.activeElement.blur();
        if(this.products.length < 1){
          this.getAllProducts(1);
        }
      },
      searchEmpty(){
        if( this.inputSearch.length < 1){
          this.clearSearch();
        }else if( this.inputSearch.length > 2){
          this.errorSearch = '';
          this.isSearch = true;
        }
      },
      applyBeClient(){
          let data = {
            shop_id: this.$route.params.id,
            user_id: this.user.user_id,
          }
        Api.post('shopkeepers/client/application/',data).then((result)=> {
          if(result.status < 300) {
            this.myNotify('success',this.$t('_success'),this.$t('home.msg_be_client'));
          }else{
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
      },
      changeProductCart(action,item){
        let myQuanty = item.quanty;
        let myShop = null;
        let myProduct = null;
        item.quanty = ( (action == 'add') ? (myQuanty+1) : (myQuanty-1) );
        if(item.quanty < 1){
          this.ROVOVE_CART_ZEROS({'shop':this.shopInfo.id,'product':item.product.id});
        }else{
          let newOrder = {
            "shop": this.shopInfo.id,
            "shop_name": this.shopInfo.name,
            "shop_picture": this.shopInfo.picture,
            "method_pay": null,
            "comment": "",
            "subtotal": 0,
            "order_min_price": parseFloat(this.shopInfo.min_price),
            "delivery_cost": parseFloat(this.shopInfo.min_shipping_price),
            "total": 0,
            "cant_total_products": 0,
            "list": this.catalog,
            "products": [],
          };
          let newProduct = {
            "name": item.product.product.name,
            "picture": item.product.product.picture,
            "price": parseFloat(item.price),
            "product_id": item.product.id,
            "cant": item.quanty,
            "master_id": item.product.product.id
          };
          //REVISAR SI YA EXISTE ORDEN Y PRODUCTOS.
          for(const o of this.cart.orden){
            if(o.shop === this.shopInfo.id){
              myShop = this.shopInfo.id;
              for(const p of o.products){
                if(p.product_id === item.product.id){
                  myProduct = p.product_id;
                  p.cant = item.quanty;
                  break;
                }
              }
              //PRODUCTO NO ESTA EN LA ORDEN.
              if(myProduct == null){
                o.products.push(newProduct);
                if(action == 'add'){
                  this.myNotify('success',this.$t('_success'),this.$t('cart.add_product'));
                }
              }
              break;
            }
          }
          //ORDEN NO ESTA EL EL CARRITO.
          if(myShop == null){
            newOrder.products.push(newProduct);
            this.cart.orden.push(newOrder);
            if(action == 'add'){
              this.myNotify('success',this.$t('_success'),this.$t('cart.add_product'));
            }
          }
        }
        this.SET_CART(this.cart);
      },
    },
    computed: {
      ...mapState(['appLoader','user','cart','products']),
      CatFilter () {
        return false ? (item, CatSearch, textKey) => item[textKey].indexOf(CatSearch) > -1 : undefined
      },
    },
  }
</script>

<style type="text/css" scoped>
  .text-ellipsis{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 60px;
  }

  .c-scroll{
    scrollbar-color: #2196F3 #E3F2FD;
  }

  .c-scroll::-webkit-scrollbar {
    width: 15px;
    border-radius: 15px;
  }

  .c-scroll::-webkit-scrollbar-track {
    background: #E3F2FD;
    border-radius: 15px;
  }

  .c-scroll::-webkit-scrollbar-thumb {
    background: #2196F3;
    border-radius: 15px;
  }

  .c-scroll::-webkit-scrollbar-thumb:hover {
    background: #2c6add;
  }
</style>